<template>
  <v-form @submit="submit" class="px-12 shadow" ref="form">
    <v-card-text class="px-12 py-4">
      <label class="label ml-3">{{ $t(`Email`) }}</label>
      <v-text-field
        solo
        name="email"
        :label="$t(`Email`)"
        type="text"
        autofocus
        v-model.trim="email"
        :rules="[rules.email.required, rules.email.validate]"
        required
      ></v-text-field>

      <label class="label ml-3">{{ $t(`Password`) }}</label>
      <v-text-field
        solo
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.password.required]"
        :type="showPass ? 'text' : 'password'"
        id="password"
        name="password"
        :label="$t(`Password`)"
        v-model.trim="password"
        @click:append="showPass = !showPass"
        required
      ></v-text-field>

      <label class="label ml-3">{{ $t(`Confirm password`) }}</label>
      <v-text-field
        solo
        :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.passwordConfirmation.required, passwordConfirmationRule]"
        :type="showPass1 ? 'text' : 'password'"
        id="passwordConfirmation"
        name="passwordConfirmation"
        :label="$t(`Confirm password`)"
        v-model.trim="passwordConfirmation"
        @click:append="showPass1 = !showPass1"
        required
      ></v-text-field>

      <div class="d-flex justify-center">
        <v-checkbox
          class="my-6"
          v-model="termsAccepted"
          :rules="[v => !!v || 'You must agree to continue!']"
          :label="$t(`I agree with Terms & Conditions`)"
          required
        ></v-checkbox>
      </div>
      <v-row no-gutters v-for="error in errors" :key="error">
        <v-col no-gutters>
          <p class="text-center red--text">{{ error }}</p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="d-flex justify-center px-12">
      <v-btn class="px-12" color="primary" type="submit" @click="submit">
        {{ $t(`Register`) }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="successfullyDialog" max-width="290" @click:outside="closeDialog">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-img
              max-width="40"
              class="ma-5"
              :src="require(`@/assets/icons/ALERT_icon.svg`)"
            ></v-img>
          </div>
          <p class="text-center">
            {{ $t("A verification link has been sent to your email.") }}
          </p>
          <p class="text-center">
            {{ $t("Please confirm your email and continue registration process") }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { me, signup } from "@/auth/auth";

export default {
  data: () => ({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirmation: "",
    showPass: false,
    showPass1: false,
    isAuthorised: me() || false,
    termsAccepted: false,
    successfullyDialog: false,
    errors: [],
    rules: {
      email: {
        required: value => !!value || "Required.",
        validate: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      password: {
        required: value => !!value || "Required."
      },
      passwordConfirmation: {
        required: value => !!value || "Required."
      }
    }
  }),
  computed: {
    passwordRule() {
      return () => this.password === this.passwordConfirmation || "Password must match";
    },
    passwordConfirmationRule() {
      return () => this.password === this.passwordConfirmation || "Password must match";
    }
  },
  props: {
    source: String
  },
  methods: {
    async submit(event) {
      this.errors = [];
      event.preventDefault();
      this.$refs.form.validate();
      try {
        await signup({
          user: {
            email: this.email,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            terms_accepted: this.termsAccepted
          }
        });
        this.successfullyDialog = true;
      } catch (e) {
        console.error("e.response.data.errors", e);
        this.errors = e.response.data.errors[0];
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      this.$router.push("/login");
    }
  },
  mounted() {
    if (this.isAuthorised) {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped lang="scss"></style>
